import React, { useState } from 'react';
import { useDataTable } from "../../../../hocks/useDataTable";
import DataTable from "../../components/header/DataTable";
import { deleteUser, getAllUsers, updateProfileAdmin } from "../../../../services/index/users";
import { images, stables } from '../../../../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const EditUser = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
        userState,
        currentPage,
        searchKeyword,
        data: usersData,
        isLoading,
        isFetching,
        isLoadingDeleteData: isLoadingDeleteUser,
        setCurrentPage,
        searchKeywordHandler,
        submitSearchKeywordHandler,
        deleteDataHandler,
    } = useDataTable({
        dataQueryFn: getAllUsers,
        dataQueryKey: "users",
        deleteDataMessage: "User is deleted",
        mutateDeleteFn: deleteUser,
    });

    // Local state to handle edits
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUserData, setEditedUserData] = useState({ name: "", email: "", password: "" });

    // Mutation for updating the user
    const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } = useMutation({
        mutationFn: ({ userId, updatedData }) => {
            return updateProfileAdmin({
                token: userState.userInfo.token,
                userData: updatedData,
                userId,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["users"]);
            toast.success("User is updated");
            setEditingUserId(null); // Reset editing state
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserData({
            ...editedUserData,
            [name]: value,
        });
    };

    const handleEditClick = (user) => {
        setEditingUserId(user._id);
        setEditedUserData({ name: user.name, email: user.email, password: "" });
    };

    const handleSaveClick = (userId) => {
        mutateUpdateUser({ userId, updatedData: editedUserData });
        navigate(`/admin/users/manage`, { replace: true });
    };

    const handleCancelClick = () => {
        setEditingUserId(null);
        navigate(`/admin/users/manage`, { replace: true });
    };

    const handleAdminCheck = (event, userId) => {
        const initialCheckValue = !event.target.checked;
        if (window.confirm("Do you want to change the admin status of this user?")) {
            mutateUpdateUser({ userId, updatedData: { admin: event.target.checked } });
        } else {
            event.target.checked = initialCheckValue;
        }
    };

    const handleVerifiedCheck = (event, userId) => {
        const initialCheckValue = !event.target.checked;
        console.log(`Initial verified status: ${initialCheckValue}`);
        if (window.confirm("Do you want to change the verified status of this user?")) {
            mutateUpdateUser({ userId, updatedData: { verified: event.target.checked } });
            console.log(`Updated verified status: ${event.target.checked}`);
        } else {
            event.target.checked = initialCheckValue;
        }
    };

    return (
        <DataTable
            pageTitle='Manage User'
            dataListName="Users"
            searchInputPlaceHolder="User email..."
            searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
            searchKeywordOnChangeHandler={searchKeywordHandler}
            searchKeyword={searchKeyword}
            tableHeaderTitleList={["Name", "Email", "Password", "Is Verified", "Is Admin", ""]}
            isLoading={isLoading}
            isFetching={isFetching}
            data={usersData?.data}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            headers={usersData?.headers}
            userState={userState}
        >
            {
                usersData?.data.map((user) => (
                    <tr key={user._id}>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <a href="#" className="relative block">
                                        <img src={user?.avatar ? stables.UPLOAD_FOLDER_BASE_URL + user?.avatar : images.userImage}
                                            className="mx-auto object-cover rounded-lg w-10 aspect-square"
                                            alt={user.name}
                                        />
                                    </a>
                                </div>
                                <div className="ml-3">
                                    {editingUserId === user._id ? (
                                        <input
                                            type="text"
                                            name="name"
                                            value={editedUserData.name}
                                            onChange={handleInputChange}
                                            className="border rounded p-1"
                                        />
                                    ) : (
                                        <p className="text-gray-900 whitespace-no-wrap">{user.name}</p>
                                    )}
                                </div>
                            </div>
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            {editingUserId === user._id ? (
                                <input
                                    type="email"
                                    name="email"
                                    value={editedUserData.email}
                                    onChange={handleInputChange}
                                    className="border rounded p-1"
                                />
                            ) : (
                                <p className="text-gray-900 whitespace-no-wrap">{user.email}</p>
                            )}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            {editingUserId === user._id ? (
                                <input
                                    type="password"
                                    name="password"
                                    value={editedUserData.password}
                                    onChange={handleInputChange}
                                    className="border rounded p-1"
                                />
                            ) : (
                                <p className="text-gray-900 whitespace-no-wrap">••••••••</p> // Hide the password
                            )}
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <input
                                type="checkbox"
                                disabled={isLoadingUpdateUser}
                                defaultChecked={user.verified}
                                onChange={(event) => handleVerifiedCheck(event, user._id)}
                            />
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <input
                                type="checkbox"
                                disabled={isLoadingUpdateUser}
                                className="d-checkbox disabled:bg-orange-400 disabled:opacity-100 
                                checked:bg-[url('../public/images/check.png')] bg-cover checked:disabled:bg-none"
                                defaultChecked={user.admin}
                                onChange={(event) => handleAdminCheck(event, user._id)}
                            />
                        </td>
                        <td className="px-5 py-5 space-x-5 text-sm bg-white border-b border-gray-200">
                            {editingUserId === user._id ? (
                                <>
                                    <button
                                        onClick={() => handleSaveClick(user._id)}
                                        className="text-green-600 hover:text-green-900"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleCancelClick}
                                        className="text-red-600 hover:text-red-900"
                                    >
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() => handleEditClick(user)}
                                        className="text-blue-600 hover:text-blue-900"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        disabled={isLoadingDeleteUser}
                                        onClick={() => deleteDataHandler({ slug: user?._id, token: userState.userInfo.token })}
                                        className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed"
                                    >
                                        Delete
                                    </button>
                                </>
                            )}
                        </td>
                    </tr>
                ))
            }
        </DataTable>
    );
};

export default EditUser;
