import axios from "axios";


export const getAllCategories = async (token, searchKeyword= '', page=1, limit=10) => {
    try {
      
      const config = {
        headers: {  
            Authorization: `Bearer ${token}`,
            },
    };
      const { data, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/api/post-categories?searchKeyword=${searchKeyword}&
          page=${page}&limit=${limit}`,config);
      console.log('Fetch Category Title:', data); // Debugging log
      return {data, headers};
    } catch (error) {
      console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const deleteCategories = async ({slug, token}) => {
    try {
      const config = {
        headers: {  
            Authorization: `Bearer ${token}`,
            },
    };
      
      //console.log('Fetching post with slug:', slug); // Debugging log
      const data = await axios.delete(`${process.env.REACT_APP_API_URL}/api/post-categories/${slug}`, config);
      //console.log('Fetch Category Title:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const createCategory = async ({token, title}) => {
    try {
      const config = {
        headers: {  
            Authorization: `Bearer ${token}`,
            },
    };
      
      //console.log('Fetching post with slug:', slug); // Debugging log
      const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/post-categories/`,{title}, config);
      console.log('Fetch Category Title:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const updateCategory = async ({ title, slug, token }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/post-categories/${slug}`, { title }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // This should contain the updated category object
    } catch (error) {
      if (error.response && error.response.data.message) {
        throw new Error(error.response.data.message);
      }
      throw new Error(error.message);
    }
  }

  export const getSingleCateogry = async ({ slug }) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/post-categories/${slug}`);
        console.log('Data from API:', response.data); // Log API response
        return response.data;
    } catch (error) {
        console.error('Error fetching category:', error); // Log error
        throw new Error(error.response?.data?.message || error.message);
    }
};