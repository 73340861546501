import React, { useEffect } from 'react';
import Header from './components/header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../../services/index/users';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const AdminLayout = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  const {
    data: profileData,
    isLoading: profileIsLoading,
  } = useQuery({
    queryFn: () => getUserProfile({ token: userState.userInfo.token }),
    queryKey: ['profile'],
    onError: (err) => {
      navigate('/');
      toast.error("You're not allowed to access the admin panel");
    },
  });

  // Use useEffect to handle navigation side-effect
  useEffect(() => {
    if (profileData && !profileData.admin) {
      navigate('/');
      toast.error("You're not allowed to access the admin panel");
    }
  }, [profileData, navigate]);

  if (profileIsLoading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <h3 className='text-2xl text-slate-300'>Loading...</h3>
      </div>
    );
  }

  return (
    <div className='flex flex-col h-screen lg:flex-row'>
      <Header />
      <main className='bg-[#F9F9F9] flex-1 p-4 lg:p-6'>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
