import axios from "axios";

export const createNewComment = async ({token , desc, slug, parent, replyOnUser}) => {
    try {
        const config = {
            headers: {  
                Authorization: `Bearer ${token}`,
                },
        };

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/comments`, {
            desc, 
            slug, 
            parent, 
            replyOnUser,
        },
            config,
        
        );
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const updateComment = async ({ token, check, desc, commentId }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`,
      {
        desc,
        check,
      },
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

// services/index/comments.js

export const deleteComment = async ({ token, commentId }) => {
  try {
    const config = {
      headers: {  
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/comments/${commentId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};


export const getAllComments = async (token, searchKeyword = "", page = 1, limit = 10) => {
    try {
        const config = {
            headers: {  
                Authorization: `Bearer ${token}`,
                },
        };
        const { data, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/api/comments?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`, config);
        return {data, headers};
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

