import React from 'react';
import { deleteComment, getAllComments, updateComment } from '../../../../services/index/comments';
import { images, stables } from '../../../../constants';
import { useDataTable } from '../../../../hocks/useDataTable';
import DataTable from '../../components/header/DataTable';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const Comments = () => {
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
 

  const {
    currentPage,
    searchKeyword,
    data: commentsData,
    isLoading,
    isFetching,
    isLoadingDeleteData: isLoadingDeleteComment,
    setCurrentPage,
    searchKeywordHandler,
    submitSearchKeywordHandler,
    deleteCommentHandler,
  } = useDataTable({
    dataQueryFn: getAllComments,
    dataQueryKey: "comments",
    deleteDataMessage: "Comment is deleted",
    mutateDeleteFn: deleteComment,
    
  });

  const { mutate: mutateUpdateCommentCheck, isLoading: isLoadingUpdateCommentCheck } = useMutation({
    mutationFn: ({ token, check, desc, commentId }) => {
      console.log("Comment ID inside mutationFn:", commentId); // Log commentId
      return updateComment({ token, check, desc, commentId });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["comments"]);
      toast.success(data?.check ? "Comment is approved" : "Comment was not approved");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  
  return (
    <DataTable 
      pageTitle="Manage Comments"
      dataListName="Comments"
      searchInputPlaceHolder="Search comments..."
      searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
      searchKeywordOnChangeHandler={searchKeywordHandler}
      searchKeyword={searchKeyword}
      tableHeaderTitleList={[
        "Author",
        "Comment",
        "In Respond to",
        "Created At",
        "",
      ]}
      isFetching={isFetching}
      isLoading={isLoading}
      data={commentsData?.data}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      userState={userState}
      headers={commentsData?.headers}
    >
      {commentsData?.data.map((comment) => (
        <tr key={comment._id}>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <a href="#" className="relative block">
                  <img 
                    src={comment?.user?.avatar 
                      ? stables.UPLOAD_FOLDER_BASE_URL + comment?.user?.avatar 
                      : images.userImage}
                    className="mx-auto object-cover rounded-lg w-10 aspect-square"
                    alt={comment?.user?.name}
                  />
                </a>
              </div>
              <div className="ml-3">
                <p className="text-gray-900 whitespace-no-wrap">
                  {comment?.user?.name}
                </p>
              </div>
            </div>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            {comment?.replyOnUser !== null && (
              <p className="text-gray-900 whitespace-no-wrap">
                In reply to <Link 
                to={`/blog/${comment?.post?.slug}/#comment-${comment?._id}`}
                className="text-blue-500"
                >{comment?.replyOnUser?.name}
                </Link>
              </p> 
            )}
            <p className="text-gray-900 whitespace-no-wrap">
              {comment?.desc}
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              <Link 
                to={`/blog/${comment?.post?.slug}`}
                className="text-blue-500"
              >{comment?.post?.title}
              </Link>
            </p>
          </td>
          <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <p className="text-gray-900 whitespace-no-wrap">
              {new Date(comment.createdAt).toLocaleDateString(
                "en-US",
                {
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                  hour:"numeric",
                  minute: "numeric",
                }
              )}
            </p>
          </td>
          <td className="px-5 py-5 space-x-5 text-sm bg-white border-b border-gray-200">
            <button
              disabled={isLoadingUpdateCommentCheck}
              onClick={() => {
                console.log("Update Clicked - Comment ID:", comment._id); // Log commentId before calling mutateUpdateCommentCheck
                mutateUpdateCommentCheck({
                  token: userState.userInfo.token,
                  check: comment?.check ? false : true,
                  desc: comment?.desc, // Pass the comment description
                  commentId: comment._id,
                });
              }}
              type="button"
              className={`${comment?.check ? "text-yellow-600 hover:text-yellow-900" : "text-green-600 hover:text-green-900"} disabled:opacity-70 disabled:cursor-not-allowed`}
            >
              {comment?.check ? "Unapprove" : "Approve"}
            </button>
            <button
              disabled={isLoadingDeleteComment}
              onClick={() => {
                console.log("Delete Clicked - Comment ID:", comment._id); // Log commentId for delete
                deleteCommentHandler({
                  commentId: comment._id, // Pass correct commentId
                  token: userState.userInfo.token,
                });
              }}
              type="button"
              className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed"
            >
              Delete
            </button>
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default Comments;
