import React from 'react'
import { AiOutlineTwitter, AiFillYoutube, AiFillInstagram,AiFillHeart } from 'react-icons/ai'
import { BsTelegram } from 'react-icons/bs'
import { FaFacebook } from 'react-icons/fa'

import {images} from '../constants'

const Footer= () => {
  return (
    <section className='bg-dark-hard'>
      <footer className='container mx-auto grid grid-cols-10 '>
       {/*
       <footer className='container mx-auto grid grid-cols-10 px-5 py-10 gap-y-10 gap-x-5 md:pt-20 md:grid-cols-12'>
       <div className='col-span-5 md:col-span-4 lg:col-span-2'>
            <h3 className='text-dark-light font-bold md:text-lg'>Products</h3>
               <ul className='text-[#959EAD] text-sm mt-5 space-y-4'>
                  <li children>
                    <a href="/">Landingpage</a>
                  </li>
                  <li children>
                    <a href="/">Features</a>
                  </li>
                  <li children>
                    <a href="/">Documentaion</a>
                  </li>
                  <li children>
                    <a href="/">RefrrealProgram</a>
                  </li>
                  <li children>
                    <a href="/">Pricing</a>
                  </li>
               </ul>
          </div>
          <div className='col-span-5 md:col-span-4 lg:col-span-2'>
            <h3 className='text-dark-light font-bold md:text-lg'>Services</h3>
               <ul className='text-[#959EAD] text-sm mt-5 space-y-4'>
                  <li children>
                    <a href="/">Landingpage</a>
                  </li>
                  <li children>
                    <a href="/">Features</a>
                  </li>
                  <li children>
                    <a href="/">Documentaion</a>
                  </li>
                  <li children>
                    <a href="/">RefrrealProgram</a>
                  </li>
                  <li children>
                    <a href="/">Pricing</a>
                  </li>
               </ul>
          </div>
          <div className='col-span-5 md:col-span-4 md:col-start-5 lg:col-span-2 lg:col-start-auto'>
            <h3 className='text-dark-light font-bold md:text-lg'>Company</h3>
               <ul className='text-[#959EAD] text-sm mt-5 space-y-4'>
                  <li children>
                    <a href="/">Landingpage</a>
                  </li>
                  <li children>
                    <a href="/">Features</a>
                  </li>
                  <li children>
                    <a href="/">Documentaion</a>
                  </li>
                  <li children>
                    <a href="/">RefrrealProgram</a>
                  </li>
                  <li children>
                    <a href="/">Pricing</a>
                  </li>
               </ul>
          </div>
          <div className='col-span-5 md:col-span-4 lg:col-span-2'>
            <h3 className='text-dark-light font-bold md:text-lg'>More</h3>
               <ul className='text-[#959EAD] text-sm mt-5 space-y-4'>
                  <li children>
                    <a href="/">Landingpage</a>
                  </li>
                  <li children>
                    <a href="/">Features</a>
                  </li>
                  <li children>
                    <a href="/">Documentaion</a>
                  </li>
               </ul>
          </div>
          <div className='col-span-10 md:order-first md:col-span-4 lg:col-span-2'>
            <img src={images.Logo} alt="Logo" className='brightness-0 invert h-20 mx-auto md:mx-0'/>
            <p className='text-sm text-dark-light text-center mt-4 md:text-left md md:text-base lg:text-sm'>
              Build a modern creative website using Tailwindcss and Reactjs
            </p>
            <ul className='flex justify-center items-center mt-5 space-x-4 text-gray-300 md:justify-start'>
               <li className='w-6 h-auto'>
                 <a href="/">
                    <AiOutlineTwitter />
                 </a>
               </li>
               <li className='w-6 h-auto'>
                <a href="/">
                  <FaFacebook />
                </a>
               </li>
               <li className='w-6 h-auto'>
                <a href="/">
                  <AiFillYoutube />
                </a>
               </li>
               <li className='w-6 h-auto'>
                <a href="/">
                  <AiFillInstagram />
                </a>
               </li>
               <li className='w-6 h-auto'>
                <a href="/">
                  <BsTelegram />
                </a>
               </li>
            </ul>
          </div>*/}
          <div className='hidden md:flex flex-col items-center space-y-4 md:col-span-12 lg:col-span-10 mb-5 mt-5'>
             <div className='bg-primary text-white p-3 rounded-full'>
                  <AiFillHeart className='w-7 h-auto' />
             </div>
             <p className='font-bold italic text-dark-light'>
                Copyright @ 2024. TradeMind with love.
             </p>
             
          </div>
      </footer>

    </section>
  )
}

export default Footer
