import React from 'react';
import { FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';
import { images } from '../../constants';
import MainLayout from '../../components/MainLayout';

const About = () => {

        return (
            <MainLayout>
                 <section className='bg-gray-100 py-10'>
            <div className='container mx-auto px-5'>
              {/* Introduction Section */}
              <div className='text-center mb-12'>
                <h1 className='text-4xl font-bold text-dark-hard mb-4'>Discover Financial Empowerment with Our Expert Content</h1>
                <p className='text-lg text-dark-light max-w-xl mx-auto'>
                Whether you're a seasoned investor or just starting your financial journey, our blog offers valuable insights and actionable advice to help you make informed decisions.
                </p>
              </div>
      
              {/* Mission and Values Section */}
            <div className='grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Stock Market Analysis</h2>
                <p className='text-dark-light text-lg text-justify'>
                Stay ahead of the curve with up-to-date stock market news, in-depth analysis, and expert predictions. Learn how to navigate the complexities of the market and make investments that align with your financial goals.
                </p>
                
              </div>

              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Investment Strategies</h2>
                <p className='text-dark-light text-lg text-justify'>
                From long-term investing to day trading, uncover strategies that suit your risk tolerance and financial objectives. Our guides break down complex investment concepts into easy-to-understand advice.
                </p>
                
              </div>

              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Online Earning Opportunities</h2>
                <p className='text-dark-light text-lg text-justify'>
                Discover legitimate ways to boost your income online. From freelancing to affiliate marketing, we explore various avenues that can help you create additional revenue streams from the comfort of your home.
                </p>
                
              </div>

              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Financial Health and Wellness</h2>
                <p className='text-dark-light text-lg text-justify'>
                Achieve financial stability with our tips on budgeting, saving, and managing debt. We provide practical advice to help you build a solid financial foundation and avoid common pitfalls.
                </p>
                
              </div>

              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Trading and Investment Tools</h2>
                <p className='text-dark-light text-lg text-justify'>
                Learn about the best tools and platforms for trading and investment. Our reviews and comparisons help you choose the right resources to enhance your trading experience and maximize returns.
                </p>
                
              </div>

              <div className='bg-white shadow-lg rounded-lg p-6 mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-4 text-center'>Career Development and Job Opportunities</h2>
                <p className='text-dark-light text-lg text-justify'>
                Explore the latest trends in the job market and find opportunities that match your skills and ambitions. Whether you’re looking for a new role or aiming to climb the corporate ladder, our resources can guide your career growth.
                </p>
                
              </div>
            </div>
              
      
              {/* Team Section */}
              <div className='text-center mb-12'>
                <h2 className='text-3xl font-bold text-dark-hard mb-8'>Meet Our Team</h2>
                <div className='grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'>
                  {teamMembers.map((member) => (
                    <div key={member.name} className='bg-white shadow-lg rounded-lg p-6'>
                      <img
                        src={member.photo}
                        alt={member.name}
                        className='w-32 h-32 rounded-full mx-auto mb-4 object-cover'
                      />
                      <h3 className='text-xl font-bold text-dark-hard'>{member.name}</h3>
                      <p className='text-dark-light'>{member.role}</p>
                      <div className='flex justify-center space-x-3 mt-4'>
                        {member.linkedin && (
                          <a href={member.linkedin} target='_blank' rel='noopener noreferrer'>
                            <FaLinkedin className='text-blue-700 w-6 h-6' />
                          </a>
                        )}
                        {member.twitter && (
                          <a href={member.twitter} target='_blank' rel='noopener noreferrer'>
                            <FaTwitter className='text-blue-500 w-6 h-6' />
                          </a>
                        )}
                        {member.facebook && (
                          <a href={member.facebook} target='_blank' rel='noopener noreferrer'>
                            <FaFacebook className='text-blue-600 w-6 h-6' />
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

            </MainLayout>
         
        );
      };
      
      const teamMembers = [
        {
          name: 'Ranjeet kr',
          role: 'Developer',
          photo: images.CEO, // Replace with your image path
          linkedin: 'https://www.linkedin.com',
          twitter: 'https://www.twitter.com',
          facebook: 'https://www.facebook.com',
        },
        
      ];
      

export default About;
