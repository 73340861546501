import React from 'react';
import { images } from '../../../constants';
import Search from '../../../components/Search';


function Hero({ handleSearch }) {
  return (
    <section className='container mx-auto flex flex-col px-5 py-5 lg:flex-row'>
      <div className='mt-10 lg:w-1/2'>
        <h1 className='font-roboto text-3xl lg:text-4xl xl:text-5xl text-center font-bold text-dark-soft md:text-5xl lg:text-left lg:max-w-[540px]'>
        Welcome to Financial Insights: Your Gateway to Wealth and Success
        </h1>
        <h3 className='font-roboto text-1xl lg:text-1xl xl:text-1xl text-center font-bold text-dark-soft md:text-1xl lg:text-left lg:max-w-[540px]'>Explore the Latest in Stock Markets, Investment Strategies, Online Earning, and Career Opportunities</h3>
        <p className='text-dark-light mt-4 text-center md:text-xl lg:text-base xl:text-xl lg:text-left'>
        Stay informed with expert analysis, tips, and trends in trading, finance, and the global economy. Unlock the potential of your investments and career with our comprehensive guides and resources. Your journey to financial freedom starts here.
        </p>
        <Search className="mt-4 lg:mt-6 xl:mt-10" onSearchKeyword={handleSearch} />
        <div className='flex mt-4 flex-col lg:flex-row lg:text-sm xl:text-base lg:items-start lg:flex-nowrap lg:gap-x-4 lg:mt-7'>
          <span className='text-dark-light lg:mt-4 mt-2 font-semibold italic'>Popular Tags:</span>
          <ul className='flex flex-wrap gap-x-2.5 gap-y-2.5 lg:text-sm xl:text-base mt-3'>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>StockMarket</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>Investing</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>FinanceTips</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>OnlineEarning</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>TradingStrategies</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>FinancialFreedom</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>Health & Fitness</li>
            <li className='rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 text-primary font-semibold'>CareerGrowth</li>
            
          </ul>
        </div>
      </div>
      
      <div className='hidden lg:block lg:1/2'>
        <img src={images.HeroImage} className='w-full' alt="user are reading an article" />
      </div>
    </section>
  );
}

export default Hero;
