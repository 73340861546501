import React from 'react'
import { useDataTable } from "../../../../hocks/useDataTable";
import DataTable from "../../components/header/DataTable";
import { deleteUser, getAllUsers,updateProfileAdmin } from "../../../../services/index/users";
import { images, stables } from '../../../../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const Users = () => {
  const queryClient = useQueryClient();
    const {
        userState,
        currentPage,
        searchKeyword,
        data: usersData,
        isLoading,
        isFetching,
        isLoadingDeleteData: isLoadingDeleteUser,
        setCurrentPage,
        searchKeywordHandler,
        submitSearchKeywordHandler,
        deleteDataHandler,
      } = useDataTable({
        dataQueryFn: getAllUsers,
        dataQueryKey: "users",
        deleteDataMessage: "User is deleted",
        mutateDeleteFn: deleteUser,
      });

          // Mutation for updating the user
const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } = 
useMutation({
        mutationFn: ({ isAdmin, userId }) => {
          return  updateProfileAdmin(
                  { 
                    token:userState.userInfo.token, 
                    userData: {admin: isAdmin},
                    userId,
                  });
      },
      onSuccess: (data) => {
          console.log("Update Success Data:", data); // Log updated data
          queryClient.invalidateQueries(["users"]);
          toast.success("User is updated");
      },
      onError: (error) => {
          toast.error(error.message);
          console.log(error);
      },
  });
const handleAdminCheck = (event, userId) => {
  const initialCheckValue = !event.target.checked;
  if(window.confirm("Do you want to change the admin status of this user")){
    mutateUpdateUser({isAdmin: event.target.checked, userId})
  }else{
    event.target.checked = initialCheckValue;
  }
};
      return (
       <DataTable 
       pageTitle='Manage User' 
       dataListName="Users" 
       searchInputPlaceHolder="User email..."
       searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
       searchKeywordOnChangeHandler={searchKeywordHandler}
       searchKeyword={searchKeyword}
       tableHeaderTitleList={["Name", "email", "CreatedAt", "isVerfied", "is Admin", ""]}
       isLoading={isLoading}
       isFetching={isFetching}
       data={usersData?.data}
       setCurrentPage={setCurrentPage}
       currentPage={currentPage}
       headers={usersData?.headers}
       userState={userState}
       >
        {
        usersData?.data.map((user) => (
                          <tr key={user._id}>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <a href="#" className="relative block">
                                    <img src={user?.avatar ? stables.UPLOAD_FOLDER_BASE_URL + user?.avatar : images.userImage}
                                      className="mx-auto object-cover rounded-lg w-10 aspect-square"
                                      alt={user.name}
                                    />
                                  </a>
                                </div>
                                <div className="ml-3">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {user.name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                              {user.email}
                              </p>
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {new Date(user.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </p>
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <p className="text-gray-900 whitespace-no-wrap">
                                {user.verified ? "✅" : "❌"}
                            </p>
                            </td>
                            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <input 
                              type='checkbox'
                              disabled={isLoadingUpdateUser}
                              className="d-checkbox disabled:bg-orange-400 disabled:opacity-100 
                              checked:bg-[url('../public/images/check.png')] bg-cover checked:disabled:bg-none"
                              defaultChecked={user.admin}
                              onChange={(event) => handleAdminCheck(event, user._id)}
                            />
                            </td>
                            <td className="px-5 py-5 space-x-5 text-sm bg-white border-b border-gray-200">
                              <button disabled={isLoadingDeleteUser} onClick={()=> {
                                deleteDataHandler({slug:user?._id, token: userState.userInfo.token})
                              }} type="button" className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed">Delete</button>
                              <Link
                              to={`/admin/users/manage/edit`}
                              className="text-green-600 hover:text-green-900">
                            Edit
                          </Link>
                            </td>
                          </tr>
                        ))
                      }
        </DataTable>
      );
};

export default Users
