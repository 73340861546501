import axios from "axios";

export const signup = async ({name, email, password}) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`,{
            name,
            email,
            password,
        });
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const login = async ({email, password}) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`,{
            email,
            password,
        });
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const getUserProfile = async ({token}) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/profile`, config);
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const updateProfile = async ({token, userData, userId}) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/updateProfileUser/${userId}`,userData, config);
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};
export const updateProfileAdmin = async ({token, userData, userId}) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/updateProfile/${userId}`,userData, config);
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const updateProfilePictuer = async ({token, formData}) => {
    try {
        const config = {
            headers: {
                "content-type" : "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/users/updateProfilePictuer`,formData, config);
        return data;
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const getAllUsers = async (token, searchKeyword = "", page = 1, limit = 10) => {
    try {
      const config = {
        headers: {  
            Authorization: `Bearer ${token}`,
            },
    };
        const { data, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/api/users?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`, config);
        return {data, headers};
    } catch (error) {
        if(error.response && error.response.data.message)
            throw new Error(error.response.data.message);
        throw new Error(error.message);
    }
};

export const deleteUser = async ({ slug, token }) => {
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
      //console.log('Fetching post with slug:', slug); // Debugging log
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${slug}`, config);
      //console.log('Fetched data:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

