import Logo from "../assets/Logo.png";
import HeroImage from "../assets/HeroImage.png"
import Post1Image from "../assets/Posts/post1.jpg"
import PostProfileImage from "../assets/Posts/PostProfile1.png"
import CtaImage from "../assets/CtaImage-01.jpg"
import CEO from "../assets/admin.png"


const samplePostImage = '/images/sample.jpg';
const userImage='/images/user.png';
const images = {
    Logo,
    HeroImage,
    Post1Image,
    PostProfileImage,
    CtaImage,
    samplePostImage,
    userImage,
    CEO,
};

export default images;