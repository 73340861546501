import React from 'react'
import {images} from '../../../constants'

function CTA() {
  return (
    <>
       <svg 
          className="w-full h-auto max-h-40 translate-y-[1px]"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 1440 175">
      <path 
          id="Layer_1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1440,175H0V0A3404.79,3404.79,0,0,0,726,77,3405.38,3405.38,0,0,0,1440,0Z"
          fill="#0D2436" />
      </svg>
      <section className='relative bg-dark-hard px-5'>
          <div className='container grid grid-cols-12 mx-auto py-10 pb-20 lg:place-items-center'>
             <div className='col-span-12 lg:col-span-6'>
                  <h2 className='text-white lg:text-left font-roboto font-bold text-2xl ms:text-4xl md:text-center md:leading-normal'>Explore the Latest in Stock Markets, Investment Strategies, Online Earning, and Career Opportunities</h2>
                  <div className='w-full max-w-[494px] mt-12 space-y-3 mx-auto md:space-y-0 md:flex md:items-center md:space-x-2 lg:mx-0'>
                    <input type="text" className='px-4 py-3 rounded-lg w-full placeholder:text-dark-light' placeholder='Email' />
                    <button className='px-4 py-3 rounded-lg w-full bg-primary text-white font-bold md:w-fit md:whitespace-nowrap'>Get started</button>
                  </div>
                  <p className='text-dark-light text-sm leading-7 mt-6 md:text-center md:text-base lg:text-left '>
                  <span className='font-bold italic text-[#B3BAC5] md:not-italic md:font-normal md:text-dark-light'>Take some photos</span> and upload them to
                  Our blog is dedicated to providing you with reliable, up-to-date information that empowers you to take control of your financial future. Whether you’re looking to grow your wealth, secure your retirement, or explore new earning opportunities, we’ve got you covered.
                  </p>
             </div>
  
             
                <div className='col-span-12 hidden mb-[70px] md:block md:order-first lg:order-last lg:col-span-6'>
                  <div className='w-3/4 mx-auto relative'>
                    <div className='w-1/2 h-1/2 bg-[#FC5A5A] rounded-lg absolute top-[10%] -right-[8%]'></div>
                    <div className='w-1/2 h-1/2 bg-white rounded-lg opacity-[.06] absolute -bottom-[10%] -left-[8%]'></div>
                    
                   <div className= "w-full rounded-xl bg-white p-3 z-[1] relative" >
                        <img src={images.CtaImage} alt="title" className='w-full object-cover object-center h-auto md:h-52 lg:h-48 xl:h-60'/>
                         <div className='p-5'>
                            <h2 className='font-roboto font-bold text-xl text-dark-soft md:text-2xl lg:text-[28px]'>Stock Market Analysis</h2>
                            <p className='text-dark-light mt-3 text-sm md:text-lg'>
                            Stay ahead of the curve with up-to-date stock market news, in-depth analysis, and expert predictions. Learn how to navigate the complexities of the market and make investments that align with your financial goals.
                            </p>
                
                         </div>
                    </div>
                </div>
             </div>
          </div>
      </section>
    </>
  );
};

export default CTA
