import { Route, Routes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import './App.css';
import Homepage from './pages/home/Homepage';
import ArticleDetailPage from './pages/home/ArticleDetail/ArticleDetailPage';
import RegisterPage from './pages/home/register/RegisterPage';
import LoginPage from './pages/login/LoginPage';
import ProfilePage from './pages/profile/ProfilePage';
import AdminLayout from './pages/admin/AdminLayout';
import Admin from './pages/admin/screens/Admin';
import Comments from './pages/admin/screens/comments/Comments';
import ManagePost from './pages/admin/screens/posts/ManagePost';
import EditPost from './pages/admin/screens/posts/EditPost';
import Categories from './pages/admin/screens/categories/Categories';
import EditCateogries from './pages/admin/screens/categories/EditCateogries';
import Users from './pages/admin/screens/users/Users';
import BlogPage from './pages/blog/BlogPage';
import About from './pages/about/About';
import EditUser from './pages/admin/screens/users/EditUser';


function App() {
  return (
    <div className='App font-opensans'>
      <HelmetProvider>
        <div>
        <Helmet>
          <title>MindeTrade</title>
          <meta name="description" content="Stay ahead in the investment world with our expert insights, market trends, and actionable tips. Discover the latest strategies to grow your wealth and make informed financial decisions." />
          <meta name="keywords" content="investment blog, trending investments, market trends, financial tips, wealth management, stock market, personal finance, investing strategies" />

        </Helmet>
        
      <Routes>
        <Route index path='/' element={<Homepage />} />
        <Route path='/about' element={<About />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/blog/:slug' element={<ArticleDetailPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/profile' element={<ProfilePage />} />
        <Route path='/admin' element={<AdminLayout />} >
          <Route index element={<Admin />} />
          <Route path="comments" element={<Comments />} />
          <Route path="posts/manage" element={<ManagePost />} />
          <Route path="categories/manage" element={<Categories />} />
          <Route path="posts/manage/edit/:slug" element={<EditPost />} />
          <Route path="categories/manage/edit/:slug" element={<EditCateogries />} />
          <Route path="users/manage" element={<Users />} />
          <Route path="users/manage/edit" element={<EditUser />} />
        </Route>

      </Routes>
      </div>
      </HelmetProvider>
      <Toaster />
     
    </div>
    
  );
}

export default App;
