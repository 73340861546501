import React, { useEffect, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import Articles from './container/Articles';
import CTA from './container/CTA';
import { useLocation, useNavigate } from 'react-router-dom';

function Homepage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearch = queryParams.get("search") || "";

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchKeyword, setSearchKeyword] = useState(initialSearch);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchKeyword === '') {
      navigate(`/`);
    } else {
      navigate(`?page=${currentPage}&search=${searchKeyword}`);
    }


  }, [currentPage, searchKeyword, navigate]);

  const handleSearch = ({ searchKeyword: newSearchKeyword }) => {
    if (newSearchKeyword !== searchKeyword) {
      setSearchKeyword(newSearchKeyword);
      setCurrentPage(1); // Reset to the first page on new search
    }
  };

  return (
    <MainLayout>
      <Hero handleSearch={handleSearch} />
      <Articles currentPage={currentPage} searchKeyword={searchKeyword} />
      <CTA />
    </MainLayout>
  );
}

export default Homepage;
