const parseJsonToHtml = (json) => {
  if (!json || !Array.isArray(json.content)) return '';

  // Example parsing logic
  const html = json.content.map(node => {
    switch (node.type) {
      case 'image':
        return `<img src="${node.attrs?.src || ''}" alt="${node.attrs?.alt || ''}" />`;
      case 'paragraph':
        return `<p>${node.content?.map(subNode => subNode.text || '').join('')}</p>`;
      // Handle other node types
      default:
        return '';
    }
  }).join('');

  return html;
};

export default parseJsonToHtml;
