import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleCateogry, updateCategory } from '../../../../services/index/postCategories';
import { useSelector } from 'react-redux';

const EditCateogries = () => {
    const [categoryTitle, setCategoryTitle] = useState("");
    const navigate = useNavigate();
    const { slug } = useParams(); // Assuming slug is used for fetching category
    const userState = useSelector((state) => state.user);
    const queryClient = useQueryClient();

    console.log("Slug from URL:", slug);

    const { data, isLoading, isError } = useQuery({
        queryFn: () => getSingleCateogry({ slug }),
        queryKey: ["categories", slug],
        onSuccess: (data) => {
            console.log("Fetch Category Data:", data); // Log fetched data
            if (data && data.title) {
                setCategoryTitle(data.title); // Set the title from the data
            } else {
                console.error("Fetched data does not contain title.");
            }
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (data && data.title) {
            setCategoryTitle(data.title); // Ensure the title is set correctly
        }
    }, [data]);

    // Mutation for updating the category
    const { mutate: mutateUpdateCategory, isLoading: isLoadingUpdateCategory } = useMutation({
        mutationFn: ({ title, slug, token }) => updateCategory({ title, slug, token }),
        onSuccess: (data) => {
            console.log("Update Success Data:", data); // Log updated data
            queryClient.invalidateQueries(["categories", slug]);
            toast.success("Category is updated");
            navigate(`/admin/categories/manage/edit/${data._id}`, { replace: true });
        },
        onError: (error) => {
            toast.error(error.message);
            console.log(error);
        },
    });

    const handleUpdateCategory = () => {
        if (!categoryTitle) return;
        mutateUpdateCategory({
            title: categoryTitle,
            slug,
            token: userState.userInfo.token,
        });
    };

    if (isLoading) return <div>Loading...</div>; // Display loading state if data is being fetched
    if (isError) return <div>Error fetching category</div>; // Handle error state

    return (
        <div className="col-span-4 py-8">
            <h4 className="text-lg leading-tight">Update Category</h4>
            <div className='d-form-control w-full mt-6'>
                <input 
                    value={categoryTitle}
                    className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto mt-4 text-dark-hard" 
                    onChange={(e) => setCategoryTitle(e.target.value)}
                    placeholder="Category title"
                />
                <button
                    disabled={isLoadingUpdateCategory || isLoading || isError}
                    type='button' 
                    onClick={handleUpdateCategory} 
                    className='w-fit mt-3 bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70'>
                    Update Category
                </button>
            </div>
        </div>
    );
};

export default EditCateogries;
