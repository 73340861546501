import React, { useEffect, useState } from 'react';
import ArticleCard from '../../components/ArticleCard';
import { useQuery } from '@tanstack/react-query';
import { getAllPosts } from '../../services/index/posts';
import toast from 'react-hot-toast';
import ArticleCardSkeleton from '../../components/ArticleCardSkletron';
import ErrorMessage from '../../components/ErrorMessage';
import { useLocation, useNavigate } from 'react-router-dom'; 
import MainLayout from '../../components/MainLayout';
import Pagination from "../../components/Pagination";
import Search from '../../components/Search';
import { Helmet } from 'react-helmet-async';

const BlogPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page")) || 1;
  const initialSearch = queryParams.get("search") || "";
  
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchKeyword, setSearchKeyword] = useState(initialSearch);
  
  const { data, isLoading, isError } = useQuery({
    queryFn: () => getAllPosts(searchKeyword, currentPage, 12),
    queryKey: ["posts", searchKeyword, currentPage],
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchKeyword === '') {
      navigate(`/blog`);
    } else {
      navigate(`?page=${currentPage}&search=${searchKeyword}`);
    }


  }, [currentPage, searchKeyword, navigate]);

  const handleSearch = ({ searchKeyword: newSearchKeyword }) => {
    if (newSearchKeyword !== searchKeyword) {
      setSearchKeyword(newSearchKeyword);
      setCurrentPage(1); // Reset to the first page on new search
    }
  };

  // Safely accessing the posts data
  const posts = Array.isArray(data?.data) ? data.data : [];

  // Ensure that the header value exists and is a valid JSON string
  const totalPageCount = data?.headers?.["x-totalpagecount"];
  const totalPageCountParsed = totalPageCount ? JSON.parse(totalPageCount) : 1;

 // Safely parse tags and log them
 const tags = Array.isArray(data?.tags) ? data.tags.join(", ") : data?.tags || "Investment, Finance, health, online earn, fitness, trade, indian market, market trends";
 console.log("Tags", tags);

  return (
    <MainLayout>
      <Helmet>
        <title>MindeTradeBlog</title>
       
          <meta name="description" content="investment blog, trending investments, market trends, financial tips, wealth management, stock market, personal finance, investing strategies" />
          <meta name="keywords" content= {tags}/>
      </Helmet>
      <Search className="w-full max-w-xl lg:ml-8" onSearchKeyword={handleSearch} />
      <section className="flex flex-col container mx-auto px-5 py-10">
        <div className="flex flex-wrap md:gap-x-5 gap-y-5 pb-10">
          {isLoading ? (
            [...Array(3)].map((_, index) => (
              <ArticleCardSkeleton
                key={index}
                className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              />
            ))
          ) : isError ? (
            <ErrorMessage message="Couldn't find any post" />
          ) : posts.length === 0 ? (
            <p className='text-orange-500'>No posts found</p>
          ) : (
            posts?.map((post) => (
              <ArticleCard
                key={post._id}
                post={post}
                className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              />
            ))
          )}
        </div>
        {!isLoading && totalPageCountParsed > 1 && (
          <Pagination
            onPageChange={(page) => setCurrentPage(page)}
            currentPage={currentPage}
            totalPageCount={totalPageCountParsed}
          />
        )}
      </section>
    </MainLayout>
  );
};

export default BlogPage;
