
const NavItemCollapse = ({ title, icon, name, activeNavName, setActiveNavName, children }) => {
  const isActive = name === activeNavName;
 

  return (
    <div className={`d-collapse ${isActive ? 'open' : ''} d-collapse-arrow bg-base-200 min-h-0 rounded-none py-2`}>
      <div
        className={`d-collapse-title font-medium min-h-0 py-0 pl-0 flex items-center gap-x-2 text-lg ${
          isActive ? 'font-bold text-primary' : 'font-semibold text-[#A5A5A5]'
        }`}
        onClick={() => setActiveNavName(isActive ? '' : name)}
      >
        {icon}
        {title}
      </div>
      {isActive && (
        <div className="mt-2 flex flex-col ml-5 p-2 gap-y-2">
          {children}
        </div>
      )}
    </div>
  );
};

export default NavItemCollapse;
