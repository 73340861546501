import React from 'react';
import { FaArrowRight } from "react-icons/fa";
import ArticleCard from '../../../components/ArticleCard';
import { useQuery } from '@tanstack/react-query';
import { getAllPosts } from '../../../services/index/posts';
import toast from 'react-hot-toast';
import ArticleCardSkeleton from '../../../components/ArticleCardSkletron';
import ErrorMessage from '../../../components/ErrorMessage';
import { Link} from 'react-router-dom'; 

const Articles = ({ currentPage, searchKeyword }) => {

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getAllPosts(searchKeyword, currentPage, 12),
    queryKey: ["posts", searchKeyword, currentPage],
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });



  // Safely accessing the posts data
  const posts = Array.isArray(data?.data) ? data.data : [];

  return (
    <section className='flex flex-col container mx-auto px-5 py-10'>
      <div className='flex flex-wrap md:gap-x-5 gap-y-5 pb-10'>
        {isLoading ? (
          [...Array(3)].map((_, index) => (
            <ArticleCardSkeleton key={index} className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]" />
          ))
        ) : isError ? (
          <ErrorMessage message="Couldn't find any posts" />
        ) : (
          posts?.map((post) => (
            <ArticleCard
              key={post._id}
              post={post}
              className='w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]'
            />
          ))
        )}
      </div>
      
      <Link
        to="/blog"
        className='mx-auto flex items-center gap-x-2 font-bold text-primary border-2 border-primary px-6 py-3 rounded-lg'>
        <span>More articles</span>
        <FaArrowRight className='w-3 h-3' />
      </Link>
    </section>
  );
};

export default Articles;
