import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MainLayout from '../../components/MainLayout';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserProfile, updateProfile } from '../../services/index/users';
import ProfilePicture from '../../components/ProfilePicture';
import { userActions } from '../../store/reducers/userReducers';
import toast from 'react-hot-toast';

const ProfilePage = () => {
  const navigate = useNavigate(); // Initialize navigate here
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);

  const {
    data: profileData,
    isLoading: profileIsLoading,
  } = useQuery({
    queryFn: () => {
      return getUserProfile({ token: userState.userInfo.token });
    },
    queryKey: ['profile'],
  });

  const { mutate, isLoading: updateprofileIsLoading } = useMutation({
    mutationFn: ({ name, email, password }) => {
      return updateProfile({
        token: userState.userInfo.token,
        userData: { name, email, password },
        userId: userState.userInfo._id,
      });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      localStorage.setItem('account', JSON.stringify(data));
      queryClient.invalidateQueries(['profile']);
      toast.success('Profile is updated');
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  useEffect(() => {
    if (!userState.userInfo) {
      navigate('/');
    }
  }, [navigate, userState.userInfo]);

  // create form validation
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
    },
    values: {
      name: profileIsLoading ? '' : profileData?.name || '',
      email: profileIsLoading ? '' : profileData?.email || '',
    },
    mode: 'onChange',
  });

  const SubmitHandler = (data) => {
    const { name, email, password } = data;
    mutate({ name, email, password });
  };

  return (
    <MainLayout>
      <section className='container mx-auto px-5 py-10'>
        <div className='w-full max-w-sm mx-auto'>
          
          <ProfilePicture avatar={profileData?.avatar} />
          <form onSubmit={handleSubmit(SubmitHandler)}>
            <div className='flex flex-col mb-6 w-full'>
              <label htmlFor='name' className='text-[#5a7184] font-semibold block'>
                Name
              </label>
              <input
                type='text'
                id='name'
                {...register('name', {
                  minLength: {
                    value: 3,
                    message: 'Enter at least 3 characters',
                  },
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                })}
                placeholder='Enter name'
                className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-sm px-5 py-4 font-semibold block outline-none border ${
                  errors.name ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
              {errors.name?.message && (
                <p className='text-red-500 text-sm ml-1'>{errors.name?.message}</p>
              )}
            </div>
            <div className='flex flex-col mb-6 w-full'>
              <label htmlFor='email' className='text-[#5a7184] font-semibold block'>
                Email
              </label>
              <input
                type='email'
                id='email'
                {...register('email', {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Email id is not valid',
                  },
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                })}
                placeholder='Enter email'
                className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-sm px-5 py-4 font-semibold block outline-none border ${
                  errors.email ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
              {errors.email?.message && (
                <p className='text-red-500 text-sm ml-1'>{errors.email?.message}</p>
              )}
            </div>
            <div className='flex flex-col mb-6 w-full'>
              <label htmlFor='password' className='text-[#5a7184] font-semibold block'>
                New Password (optional)
              </label>
              <input
                type='password'
                id='password'
                {...register('password')}
                placeholder='Enter new password'
                className={`placeholder:text-[#959ead] text-dark-hard mt-3 rounded-sm px-5 py-4 font-semibold block outline-none border ${
                  errors.password ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
              {errors.password?.message && (
                <p className='text-red-500 text-sm ml-1'>{errors.password?.message}</p>
              )}
            </div>
            <button
              type='submit'
              disabled={!isValid || profileIsLoading ||updateprofileIsLoading}
              className={`${
                isValid ? 'bg-primary' : 'bg-red-500'
              } disabled:opacity-70 disabled:cursor-no-drop text-white font-bold text-lg py-4 px-8 w-full rounded-lg mb-6`}
            >
              Update
            </button>
          </form>
        </div>
      </section>
    </MainLayout>
  );
};

export default ProfilePage;
