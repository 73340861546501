import { deletePost, getAllPostsAdmin } from "../../../../services/index/posts";
import { images, stables } from "../../../../constants";


import { Link } from "react-router-dom";
import { useDataTable } from "../../../../hocks/useDataTable";
import DataTable from "../../components/header/DataTable";

const ManagePost = () => {
  const {
    userState,
    currentPage,
    searchKeyword,
    data: postsData,
    isLoading,
    isFetching,
    isLoadingDeleteData: isLoadingDeletePost,
    setCurrentPage,
    searchKeywordHandler,
    submitSearchKeywordHandler,
    deleteDataHandler: deletePostHandler,
  } = useDataTable({
    dataQueryFn: getAllPostsAdmin,
    dataQueryKey: "posts",
    deleteDataMessage: "Post is deleted",
    mutateDeleteFn: deletePost,
  });

  return (
   <DataTable 
   pageTitle='Manage Post' 
   dataListName="Posts" 
   searchInputPlaceHolder="Post title..."
   searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
   searchKeywordOnChangeHandler={searchKeywordHandler}
   searchKeyword={searchKeyword}
   tableHeaderTitleList={["Title", "Category", "CreatedAt", "Tags", ""]}
   isLoading={isLoading}
   isFetching={isFetching}
   data={postsData?.data}
   setCurrentPage={setCurrentPage}
   currentPage={currentPage}
   headers={postsData?.headers}
   userState={userState}
   >
    {
    postsData?.data.map((post) => (
                      <tr key={post._id}>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <a href="#" className="relative block">
                                <img src={post?.photo ? stables.UPLOAD_FOLDER_BASE_URL + post?.photo : images.samplePostImage}
                                  className="mx-auto object-cover rounded-lg w-10 aspect-square"
                                  alt={post.title}
                                />
                              </a>
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                {post.title}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                          <p className="text-gray-900 whitespace-no-wrap">
                          {
                            post.categories.length > 0 
                            ? post.categories.slice(0, 3)
                            .map((category, index) => (
                              `${category.title}${post.categories.slice(0, 3)
                                .length === index + 1 ? "" : ", "}`
                            )) : "Uncategorized"
                            
                            }
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {new Date(post.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </p>
                        </td>
                        <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                          <div className="flex gap-x-2">
                            {post.tags.length > 0 ? post.tags.map((tag, index) => (
                              <p key={index}>{tag}{post.tags.length - 1 !== index && ','}</p>
                            )) : "No tags"}
                          </div>
                        </td>
                        <td className="px-5 py-5 space-x-5 text-sm bg-white border-b border-gray-200">
                          <button disabled={isLoadingDeletePost} onClick={()=> {
                            deletePostHandler({slug:post?.slug, token: userState.userInfo.token})
                          }} type="button" className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed">Delete</button>
                          <Link
                          to={`/admin/posts/manage/edit/${post?.slug}`}
                          className="text-green-600 hover:text-green-900">
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
    </DataTable>
  );
};

export default ManagePost;
