import { Link, useNavigate, useParams } from "react-router-dom";
import { useDataTable } from "../../../../hocks/useDataTable";
import { createCategory, deleteCategories, getAllCategories } from "../../../../services/index/postCategories";
import DataTable from "../../components/header/DataTable";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const Categories = () => {

  const[categoryTitle, setCategoryTitle]=  useState("");
  const {slug} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate:mutateCreateCategoy, isLoading: isLoadingCreateCategory } = useMutation({
    mutationFn: ({token,title,slug}) => {
      return createCategory({
        token,
        title,
        slug,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['categories']);
      toast.success('Category is create successfully');
      console.log(data);
      navigate(`/admin/categories/manage`);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });
    const {
        userState,
        currentPage,
        searchKeyword,
        data: CategoriesData,
        isLoading,
        isFetching,
        isLoadingDeleteData: isLoadingDeleteCategory,
        setCurrentPage,
        searchKeywordHandler,
        submitSearchKeywordHandler,
        deleteDataHandler,
      } = useDataTable({
        dataQueryFn: getAllCategories,
        dataQueryKey: "Categories",
        deleteDataMessage: "Category is deleted",
        mutateDeleteFn: deleteCategories,
      });
    const handleCreateCategory = () => {
        mutateCreateCategoy({
            token:userState.userInfo.token,
            slug,
            title: categoryTitle,
        });
    }
  return (
    <div className="grid grid-col-12 gap-x-4">
        <div className="col-span-4 py-8">
            <h4 className="text-lg leading-tight">Add New Cateories</h4>
            <div className='d-form-control w-full mt-6 '>
                  
                <input 
                value={categoryTitle} 
                className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto mt-4 text-dark-hard"
                onChange={(e) => setCategoryTitle(e.target.value)}
                placeholder='Category title'
                />
                <button
                 disabled={isLoadingCreateCategory}
                 type='button' 
                 onClick={handleCreateCategory} className='w-fit mt-3 bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70'>
                    Add Cetegory
                </button>
                
            </div>
        </div>
        <div className="col-span-8">
              <DataTable 
    pageTitle='' 
    dataListName="Categories" 
    searchInputPlaceHolder="Category title..."
    searchKeywordOnSubmitHandler={submitSearchKeywordHandler}
    searchKeywordOnChangeHandler={searchKeywordHandler}
    searchKeyword={searchKeyword}
    tableHeaderTitleList={["Title","CreatedAt", "Tags", ""]}
    isLoading={isLoading}
    isFetching={isFetching}
    data={CategoriesData?.data}
    setCurrentPage={setCurrentPage}
    currentPage={currentPage}
    headers={CategoriesData?.headers}
    userState={userState}
    >
     {
     CategoriesData?.data.map((category) => (
                       <tr key={category._id}>
                         <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                           <div className="flex items-center">
                               <p className="text-gray-900 whitespace-no-wrap">
                                 {category.title}
                               </p> 
                           </div>
                         </td>
                        
                         <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                           <p className="text-gray-900 whitespace-no-wrap">
                             {new Date(category.createdAt).toLocaleDateString(
                               "en-US",
                               {
                                 day: "numeric",
                                 month: "short",
                                 year: "numeric",
                               }
                             )}
                           </p>
                         </td>
                        
                         <td className="px-5 py-5 space-x-5 text-sm bg-white border-b border-gray-200">
                           <button disabled={isLoadingDeleteCategory} onClick={()=> {
                             deleteDataHandler({slug:category?._id, token: userState.userInfo.token})
                           }} type="button" className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed">Delete
                           </button>
                           <Link
                           to={`/admin/categories/manage/edit/${category?._id}`}
                           className="text-green-600 hover:text-green-900">
                             Edit
                           </Link>
                         </td>
                       </tr>
                     ))
                   }
              </DataTable>
         </div>
     </div>
  );
};

export default Categories
