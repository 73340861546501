import React, { useState } from 'react'

import CommentForm from './CommentForm';
import Comment from './Comment';
import {useMutation, useQueryClient} from "@tanstack/react-query"
import { createNewComment, deleteComment, updateComment } from '../../services/index/comments';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const CommentsContainer = ({ className, logginedUserId, comments = [], postSlug }) => {
  
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);

  const [affectedComment, setAffectedComment] = useState(null);

  const { mutate: mutateNewComment, isLoading: isLoadingNewComment } = useMutation({
      mutationFn: ({ token, desc, slug, parent, replyOnUser }) => {
          return createNewComment({ token, desc, slug, parent, replyOnUser });
      },
      onSuccess: () => {
          toast.success("Your comment has been sent successfully. It will be visible when confirmed by an admin."
        );
        queryClient.invalidateQueries('comments');
      },
      onError: (error) => {
          toast.error(error.message);
          console.log(error);
      },
  });

  const { mutate: mutateUpdateComment } = useMutation({
    mutationFn: ({ token, desc, commentId }) => {
        return updateComment({ token, desc, commentId});
    },
    onSuccess: () => {
        toast.success("Your comment is updated successfully");
        setTimeout(() => {
        queryClient.invalidateQueries('comments');
      }, 2000); // Delay the invalidation by 2 seconds
        
    },
    onError: (error) => {
        toast.error(error.message);
        console.log(error);
    },
});

const { mutate: mutateDeleteComment } = useMutation({
    mutationFn: ({ token, commentId }) => {
        return deleteComment({ token, commentId});
    },
    onSuccess: () => {
        toast.success("Your comment is deleted successfully");
        setTimeout(() => {
        queryClient.invalidateQueries('comments');
      }, 2000); // Delay the invalidation by 2 seconds
        
    },
    onError: (error) => {
        toast.error(error.message);
        console.log(error);
    },
});

  const addCommentHandler = (value, parent = null, replyOnUser = null) => {
      // Check for user authentication
      if (!userState || !userState.userInfo || !userState.userInfo.token) {
          toast.error("User not authenticated");
          console.log('User State:', userState);
          return;
      }

      mutateNewComment({
          desc: value,
          parent,
          replyOnUser,
          token: userState.userInfo.token,
          slug: postSlug
      });
      setAffectedComment(null);
  };

  const updateCommentHandler = (value, commentId) => {
    console.log('Updating comment with ID:', commentId);
  if (!userState || !userState.userInfo || !userState.userInfo.token) {
    toast.error("User not authenticated");
    console.log('User State:', userState);
    return;
  }
      mutateUpdateComment({
        token: userState.userInfo.token,
        desc:value,
        commentId,
      })
      setAffectedComment(null);
  };

  const deleteCommentHandler = (commentId) => {
    console.log('Attempting to delete comment with ID:', commentId);
    mutateDeleteComment({
        token:userState.userInfo.token, 
        commentId
    })
  };

  return (
      <div className={`${className}`}>
          <CommentForm 
              btnLabel='Send'
              formSubmitHandler={(value) => addCommentHandler(value)} 
              loading={isLoadingNewComment}
          />
          <div className='space-y-4 mt-8'>
              {comments.map((comment) => (
                  <Comment 
                      key={comment._id}
                      comment={comment} 
                      logginedUserId={logginedUserId} 
                      affectedComment={affectedComment}
                      setAffectedComment={setAffectedComment}
                      addComment={addCommentHandler}
                      updateComment={updateCommentHandler}
                      deleteComment={deleteCommentHandler}
                      replies={comment.replies}
                  />
              ))}
          </div>
      </div>
  );
};



export default CommentsContainer
