import React, { useState } from 'react'

const CommentForm = ({
  btnLabel, 
  formSubmitHandler, 
  formCancelHandler = null,
  initialText= "",
  loading = false,
 }) => {
    const [value, setValue] = useState(initialText);
    const submitHandler = (e ) =>{
        e.preventDefault();
        formSubmitHandler(value);
        setValue("");
    };
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2'>
    <form onSubmit={submitHandler}>
        <div className='flex flex-col items-end border border-primary rounded-lg p-4'>
            <textarea 
            className='w-full focus:outline-none bg-transparent' 
            rows='5' 
            placeholder='Leave your comment here...' 
            value={value} 
            onChange={(e) => setValue(e.target.value)} 
            />
            <div className='flex flex-col-reverse gap-y-2 items-centergap-x-2 min-[420px]:flex-row'>
              {formCancelHandler && (
                <button onClick={formCancelHandler} className='px-6 py-2.5 rounded-lg border border-red-500 text-red-500'>
                  Cancel
                  </button>
              )}
                 <button
                  disabled= {loading} 
                  type='submit' 
                  className='px-6 py-2.5 rounded-lg bg-primary text-white font-semibold disabled:opacity-70 disabled:cursor-not-allowed'>
                   {btnLabel}
                 </button>
            </div>
            
        </div>
      
    </form>
    </div>
  );
};

export default CommentForm

