import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../../../constants';
import { AiFillDashboard, AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { FaComments, FaUser } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import NavItemCollapse from './NavItemCollapse';
import Navitem from './Navitem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { createPost } from '../../../../services/index/posts';



const Header = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeNavName, setActiveNavName] = useState('dashboard');
  const windowSize = useWindowSize();

  const { mutate:mutateCreatePost, isLoading: isLoadingCreatePost } = useMutation({
    mutationFn: ({token}) => {
      return createPost({
        token,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['posts']);
      toast.success('Post is create successfully');
      console.log(data);
      navigate(`/admin/posts/manage/edit/${data.slug}`);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const closeMenuHandler = () => {
    setIsMenuActive(false);
  };

  useEffect(() => {
    if (windowSize.width >= 1024) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }
  }, [windowSize.width]);

const handleCreateNewPost = ({token}) => {
   mutateCreatePost({token})
}
  return (
    <header className="flex h-fit w-full items-center justify-between p-4 lg:p-0 lg:h-full lg:w-full lg:max-w-[300px] lg:flex-col lg-items-start lg:justify-start">
      {/* logo */}
      <Link to="/">
        <img src={images.Logo} alt="logo" className="w-16 lg:hidden" />
      </Link>
      {/* menu burger icon */}
      <div className="cursor-pointer">
        {windowSize.width < 1024 && (
          isMenuActive ? (
            <AiOutlineClose className="w-6 h-6" onClick={toggleMenuHandler} />
          ) : (
            <AiOutlineMenu className="w-6 h-6" onClick={toggleMenuHandler} />
          )
        )}
      </div>
      {/*sidebar container*/}
      {isMenuActive && (
        <div className="fixed inset-0 lg:static lg:h-full lg:w-full">
          {/* underlay*/}
          <div className="fixed inset-0 bg-black opacity-60 lg:hidden" onClick={toggleMenuHandler}></div>
          {/*sidebar */}
          <div className="fixed top-0 bottom-0 left-0 z-50 w-3/4 overflow-y-auto bg-white p-4 lg:static lg:h-full lg:w-full lg:p-6">
            <Link to="/">
              <img src={images.Logo} alt="logo" className="w-16" />
            </Link>
            <h4 className="mt-10 font-bold text-[#C7C7C7]">Main Menu</h4>
            {/* MENU items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              
                  <Navitem
                    title="Dashboard"
                    link="/admin"
                    icon={<AiFillDashboard className="text-xl" />}
                    name="dashboard"
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                    closeMenu={closeMenuHandler}
                  />
                   <Navitem
                    title="Comments"
                    link="/admin/comments"
                    icon={<FaComments className="text-xl" />}
                    name="comments"
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                    closeMenu={closeMenuHandler}
                  />
             
                  <NavItemCollapse
                    title="Posts"
                    icon={<MdDashboard className="text-xl" />}
                    name="posts"
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                  >
                    <Link to="/admin/posts/manage">Manage all Posts</Link>
                    <button 
                    disabled={isLoadingCreatePost}
                    className='text-start disabled:opacity-60 disabled:cursor-not-allowed' 
                    onClick={() => handleCreateNewPost({token:userState.userInfo.token})}>Add New Post</button>
                    <Link to="/admin/categories/manage">Categories</Link>
                  </NavItemCollapse>

                  <Navitem
                    title="Users"
                    link="/admin/users/manage"
                    icon={<FaUser className="text-xl" />}
                    name="users"
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                    closeMenu={closeMenuHandler}
                  />
              
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
