import axios from "axios";


export const getAllPosts = async (searchKeyword = "", page = 1, limit = 10) => {
  try {
    const { data, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`);
    return {data, headers};
  } catch (error) {
    console.error("Error fetching posts:", error);  // Add logging here
    if(error.response && error.response.data.message)
        throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};
export const getAllPostsAdmin = async (token, searchKeyword = "", page = 1, limit = 10) => {
  try {
    const config = {
      headers: {  
          "Content-Type": "multipart/form-data", 
           Authorization: `Bearer ${token}`,
          },
  };
      const { data, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`, config);
      return {data, headers};
  } catch (error) {
      if(error.response && error.response.data.message)
          throw new Error(error.response.data.message);
      throw new Error(error.message);
  }
};

export const getSinglePost = async ({ slug }) => {
    try {
      //console.log('Fetching post with slug:', slug); // Debugging log
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${slug}`);
      //console.log('Fetched data:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const deletePost = async ({ slug, token }) => {
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
      //console.log('Fetching post with slug:', slug); // Debugging log
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${slug}`, config);
      //console.log('Fetched data:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const updatePost = async ({ updatedData, slug, token }) => {
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
      //console.log('Fetching post with slug:', slug); // Debugging log
      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/${slug}`,updatedData, config);
      //console.log('Fetched data:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };

  export const createPost = async ({ token }) => {
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
      //console.log('Fetching post with slug:', slug); // Debugging log
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/posts/`, {}, config);
      console.log('Fetch data:', data); // Debugging log
      return data;
    } catch (error) {
      //console.error('Error fetching post:', error); // Debugging log
      if (error.response && error.response.data.message)
        throw new Error(error.response.data.message);
      throw new Error(error.message);
    }
  };


