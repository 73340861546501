import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useMutation,useQuery, useQueryClient } from '@tanstack/react-query';
import { HiOutlineCamera } from 'react-icons/hi';
import { getSinglePost, updatePost } from '../../../../services/index/posts';
import ArticleDetailSkleton from '../../../home/ArticleDetail/components/ArticleDetailSkleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { stables } from '../../../../constants';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable'
import Editor from '../../../../components/editor/Editor';
import MultiSelectTagDropDown from '../../components/header/select-dropdown/MultiSelectTagDropDown';
import { getAllCategories } from '../../../../services/index/postCategories';
import {categoryToOption, filterCategories} from '../../../../utils/multiSelectTagUtils'

const promiseOptions = async (inputValue) => {
  const {data:categoriesData} = await getAllCategories();

  return filterCategories(inputValue, categoriesData);

}


const EditPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [body, setBody] = useState(null);
  const [categories, setCategories]= useState(null);
  const [title, setTitle]= useState('');
  const [tags, setTags]= useState('');
  const [postSlug, setPostSlug]= useState(slug);
  const [caption, setCaption]= useState('');

  const { data, isLoading, isError: error } = useQuery({
    queryFn: () => getSinglePost({ slug }),
    queryKey: ["blog", slug],
  });


  const {mutate:mutateUpdatePostDetail, isLoading: isLoadingUpdatePostDetail} = useMutation({
    mutationFn: ({updatedData, slug, token}) => {
      return updatePost({
        updatedData,
        slug,
        token,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["blog", slug]);
      toast.success("Post is updated");
      navigate(`/admin/posts/manage/edit/${data.slug}`, {replace:true});

    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  useEffect(() => {
    if (!isLoading && !error && data) {
      setInitialPhoto(data.photo);
      setCategories(data.categories.map((item => item._id)));
      setTitle(data.title);
      setTags(data.tags);
      setCaption(data.caption);
    }
  }, [isLoading, error, data]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const handleUpdatePost = async () => {
    let updatedData = new FormData();
    if(!initialPhoto && photo){
      updatedData.append("postPicture", photo)
    }else if (initialPhoto && !photo){
      const urlToObject = async(url) => {
        let response = await fetch(url);
        let blob = await response.blob();
        const file = new File([blob], initialPhoto, {type: blob.type});
        return file;
      };
      const picture = await urlToObject(stables.UPLOAD_FOLDER_BASE_URL + data?.photo);
      updatedData.append("postPicture", picture);
    }
    updatedData.append("document", JSON.stringify({body,categories, title, tags, caption, slug: postSlug}));

    mutateUpdatePostDetail({updatedData, slug, token: userState.userInfo.token})
  };

const handleDeleteImage = () => {
  if(window.confirm("Do you want to delete post picture?")){
    setInitialPhoto(null);
    setPhoto(null);
  }
};
let isPostDataLoaded = !isLoading && !error;

  return (
    <div>
      {isLoading ? (
        <ArticleDetailSkleton />
      ) : error ? (
        <ErrorMessage message="Couldn't fetch the post detail" />
      ) : (
        <section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
          <article className="flex-1">
            <label htmlFor='postPicture' className='w-full cursor-pointer'>
              {photo ? (
                <img src={URL.createObjectURL(photo)} alt={data.title} className='rounded-xl w-full' />
              ) : initialPhoto ? (
                <img src={stables.UPLOAD_FOLDER_BASE_URL + initialPhoto} alt={data.title} className='rounded-xl w-full' />
              ) : (
                <div className='w-full min-h-[200px] bg-blue-50/50 flex justify-center items-center'>
                  <HiOutlineCamera  className="w-7 h-auto text-primary" />
                </div>
              )}
            </label>
            <input type="file" className='sr-only' id="postPicture" onChange={handleFileChange} />
            <button type='button' onClick={handleDeleteImage} className='w-fit bg-red-500 text-white font-semibold rounded-lg px-2 py-1 mt-5'>Delete Image</button>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div className="lg:col-span-2">
                <div className="mt-4 flex gap-2">
                  {data?.categories && data.categories.map((category) => (
                    <Link
                      to={`/blog?category=${category.name}`}
                      className="text-primary text-sm font-roboto inline-block md:text-base"
                      key={category.name}
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
                <div className='d-form-control w-full'>
                  <label className='d-label' htmlFor='title'>
                      <span className='d-label-text'>Title</span>
                  </label>
                <input 
                id= 'title'
                value={title} 
                className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto mt-4 text-dark-hard"
                onChange={(e) => setTitle(e.target.value)}
                placeholder='title'
                />
                
                </div>

                <div className='d-form-control w-full'>
                  <label className='d-label' htmlFor='caption'>
                      <span className='d-label-text'>caption</span>
                  </label>
                <input 
                id= 'caption'
                value={caption} 
                className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto mt-4 text-dark-hard"
                onChange={(e) => setCaption(e.target.value)}
                placeholder='caption'
                />
                
                </div>

                <div className='d-form-control w-full'>
                  <label className='d-label' htmlFor='title'>
                      <span className='d-label-text'>slug</span>
                  </label>
                <input 
                id= 'slug'
                value={postSlug} 
                className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto mt-4 text-dark-hard"
                onChange={(e) => setPostSlug(e.target.value.replace(
                  /\s+/g, "-"
                ).toLowerCase())}
                placeholder='post slug'
                />
                
                </div>

        
                <div className='mb-5 mt-2'>
                <label className='d-label'>
                      <span className='d-label-text'>categories</span>
                  </label>
                  {isPostDataLoaded && (
                    <MultiSelectTagDropDown 
                    loadOptions={promiseOptions} 
                    defaultValue={data.categories.map(categoryToOption)}
                    onChange={(newValue) => 
                      setCategories(newValue.map((item => item.value))
                    )}/>
                    
                    )}
                  
                </div>
                <div className='mb-5 mt-2'>
                <label className='d-label'>
                      <span className='d-label-text'>tags</span>
                  </label>
                  {isPostDataLoaded && (
                    <CreatableSelect 
                    defaultValue={data.tags.map((tag) => ({
                      value: tag,
                      label: tag,
                    }))}
                    isMulti
                    onChange={(newValue) => 
                      setTags(newValue.map((item) => item.value))
                  }
                  className='relative z-20'
                  />
                    )}
                  
                </div>
                <div className='w-full'>
                  {isPostDataLoaded && (
                    <Editor onDataChange={(data) =>{
                      setBody(data);
                    }} 
                    content={data?.body} editable={true}/>
                  )}
                   
                </div>
                <button
                 disabled={isLoadingUpdatePostDetail}
                 type='button' onClick={handleUpdatePost} className='w-full bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70'>
                    Update Post
                </button>
              </div>
            </div>
          </article>
        </section>
      )}
    </div>
  );
};

export default EditPost;
