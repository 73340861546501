import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import MainLayout from '../../../components/MainLayout';
import BreadCrumbs from '../../../components/BreadCrumbs';
import { images, stables } from "../../../constants";
import SuggestedPost from './container/SuggestedPost';
import CommentsContainer from '../../../components/comments/CommentsContainer';
import SocialShareButton from '../../../components/SocialShareButton';
import { getAllPosts, getSinglePost } from '../../../services/index/posts';
import ArticleDetailSkleton from './components/ArticleDetailSkleton';
import ErrorMessage from '../../../components/ErrorMessage';
import { useSelector } from 'react-redux';
import parseJsonToHtml from '../../../utils/parseJsonToHtml';
import Editor from '../../../components/editor/Editor';
import { Helmet } from 'react-helmet-async';

const ArticleDetailPage = () => {
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);
  const [breadCrumbsData, setBreadCrumbsData] = useState([]);
  const [body, setBody] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [postsData, setPostsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const data = await getSinglePost({ slug });
        console.log('Article Data:', data);

        setBreadCrumbsData([
          { name: 'Home', link: '/' },
          { name: 'Blog', link: '/blog' },
          { name: data.title, link: `/blog/${data.slug}` },
        ]);

        setBody(parseJsonToHtml(data?.body));
        setData(data);
      } catch (err) {
        console.error('Error fetching article:', err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const posts = await getAllPosts();
        setPostsData(posts);
      } catch (err) {
        console.error('Error fetching posts:', err);
      } finally {
        setIsLoading(false);
      }
    };
    window.scrollTo(0, 0);

    fetchPosts();
  }, []);
  return (
    <MainLayout>
      <Helmet>
          <title>{data?.title}</title>
          <meta name="description" content={data?.caption} />
          <meta name="keywords" content= {data?.tags}/>

        </Helmet>
      {isLoading ? (
        <ArticleDetailSkleton />
      ) : error ? (
        <ErrorMessage message="couldn't fetch the post detail" />
      ) : (
        <section className='container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start'>
          <article className='flex-1'>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
              <div className='lg:col-span-2'>
                <BreadCrumbs data={breadCrumbsData} />
                <img src={data?.photo ? stables.UPLOAD_FOLDER_BASE_URL + data?.photo : images.samplePostImage} className='rounded-xl w-full' alt={data?.title} />
                <div className='mt-4 flex gap-2'>
                  {data?.categories.map((category) => (
                    <Link
                      to={`/blog?category=${category.name}`}
                      className='text-primary text-sm font-roboto inline-block md:text-base'
                      key={category.id}
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>
                <h1 className='text-xl font-medium font-roboto mt-4 text-dark-hard md:text-[26px]'>
                  {data?.title}
                </h1>
                <div className='w-full'>
                  {!isLoading && !error && (
                    <Editor 
                      content={data?.body} 
                      editable={false}
                    />
                  )}
                </div>
              </div>
              <div className='lg:col-span-1'>
                <SuggestedPost
                  header="Latest Article"
                  posts={postsData?.data}
                  tags={data?.tags}
                  className="mt-8 lg:mt-0 lg:max-w-xs lg:ml-auto"
                />
              </div>
              <div>
                <h2 className='font-roboto font-medium text-dark-hard mb-4 md:text-xl '>
                  Share On:
                </h2>
                <SocialShareButton 
                  url={encodeURI(window.location.href)} 
                  title={encodeURIComponent(data?.title)} 
                />
              </div>
            </div>
            <CommentsContainer 
              comments={data?.comments}
              className='mt-10' 
              logginedUserId={userState?.userInfo?._id || null}
              postSlug={slug}
            />
          </article>
        </section>
      )}
    </MainLayout>
  );
};

export default ArticleDetailPage;
