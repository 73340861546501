import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export const useDataTable = ({
  
  dataQueryFn,
  dataQueryKey,
  mutateDeleteFn,
  deleteDataMessage,
  
  
  
}) => {
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const token = userState.userInfo.token; // Extract token
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryFn: () => dataQueryFn(token, searchKeyword, currentPage),
    queryKey: [dataQueryKey, searchKeyword, currentPage],
    keepPreviousData: true,
  });

  const { mutate: mutateDeleteData, isLoading: isLoadingDeleteData } = useMutation({
    mutationFn: mutateDeleteFn,
    onSuccess: () => {
      queryClient.invalidateQueries([dataQueryKey, searchKeyword, currentPage]);
      toast.success(deleteDataMessage);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
  });

  useEffect(() => {
    refetch();
  }, [searchKeyword, currentPage, refetch]);

  const searchKeywordHandler = (e) => {
    const { value } = e.target;
    setSearchKeyword(value);
  };

  const submitSearchKeywordHandler = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    refetch();
  };
  const deleteCommentHandler = ({ commentId, token }) => {
    mutateDeleteData({ commentId, token });
  }

  const deleteDataHandler = ({ slug, token }) => {
    if(window.confirm("Do you want to delete this records")){
      mutateDeleteData({ slug, token });
    }
  };

  return {
    userState,
    currentPage,
    searchKeyword,
    data,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    queryClient,
    searchKeywordHandler,
    submitSearchKeywordHandler,
    deleteDataHandler,
    setCurrentPage,
    deleteCommentHandler,
  };
};
