import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import { Color } from '@tiptap/extension-color';
import { ListItem } from '@tiptap/extension-list-item';
import { TextStyle } from '@tiptap/extension-text-style';
import { StarterKit } from '@tiptap/starter-kit';
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight';
import { createLowlight } from 'lowlight';



// Create a lowlight instance
const lowlight = createLowlight();

// Pass languages directly to CodeBlockLowlight
export const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // Marks are preserved, but attributes are not
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // Marks are preserved, but attributes are not
    },
  }),
  CodeBlockLowlight.configure({
    lowlight, // Pass the lowlight instance here
    // If needed, you can specify additional options, but not language registration
  }),
  Dropcursor,
  Image
];

export default extensions;
